import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import useSWR from 'swr';

const BACKEND_RUNNER_URL_LOCAL = `http://localhost:3037`; // `https://0a08-185-236-200-27.ngrok.io`; // `http://localhost:3037`;
// script executor (can be a server or local ngrok url)
// https://little-mayfly-74.telebit.io
// https://webbot.loophole.site
// https://af33-98-47-253-148.ngrok.io
// https://screenf.herokuapp.com
const BACKEND_RUNNER_URL = `https://cd63-98-47-253-148.ngrok.io`;

const isLocalhost = window.location.host.indexOf('localhost') >= 0;

export const getWorkerUrl = () => {
  const baseUrl = isLocalhost ? BACKEND_RUNNER_URL_LOCAL : BACKEND_RUNNER_URL;
  return baseUrl;
};

export const getBaseUrl = () => {
  const baseUrl = isLocalhost ? 'http://localhost:3035' : `https://${window.location.host}`;
  return baseUrl;
};

export const getBaseApiUrl = () => {
  return getBaseUrl() + '/api/v1';
};

// base URL and Path of API endpoints:
const BaseUrl = getBaseUrl();
let BaseApiUrl = getBaseApiUrl();

// set BaseApiUrl (for example: at runtime)
export const setBaseApiUrl = (newBaseApiUrl: string): void => {
  BaseApiUrl = newBaseApiUrl;
};

export const ApiConfig = {
  get BaseUrl() {
    return BaseUrl;
  },
  get BaseApiUrl() {
    return BaseApiUrl;
  }
};

export const BaseAxiosConfig: AxiosRequestConfig = {
  baseURL: BaseApiUrl,
  headers: { 'Content-Type': 'application/json' }
};

export function getLoginData() {
  try {
    const loginDataStr = atob(localStorage.getItem('ld') || '') || '{}'; // base64 => string
    const loginData = JSON.parse(loginDataStr);
    return { loginData, userId: loginData?.data?.user?.id ?? '', userEmail: loginData?.data?.user?.email ?? '' };
  } catch {
    return { loginData: null, userId: '', userEmail: '' };
  }
}

const axiosApi = axios.create({ ...BaseAxiosConfig });

axiosApi.interceptors.request.use((config) => {
  const { loginData } = getLoginData();
  if (loginData?.data?.token?.accessToken) {
    config.headers.Authorization = `Bearer ${loginData?.data?.token?.accessToken}`;
  }
  return config;
});

const onError = function (error: AxiosError) {
  console.error('Request Failed:', error.config);
  if (error.response) {
    // Request was made but server responded with something
    // other than 2xx
    console.error('Status:', error.response.status);
    console.error('Data:', error.response.data);
    console.error('Headers:', error.response.headers);
  } else {
    // Something else happened while setting up the request
    // triggered the error
    console.error('Error Message:', error.message);
  }
  return Promise.resolve({ data: null, status: error?.response?.status, error }); // .reject(error.response || error.message);
};

interface ResponseInterface {
  status?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
  error?: AxiosError;
}

// custom fetch function for useSWR
export const swrFetcher = (url: string, options?: AxiosRequestConfig) =>
  axiosApi({ url, method: 'GET', ...options }).catch(onError);

// apiGet - uses axios GET - path can be a relative path (uses BaseApiUrl) or absolute url path (startsWith http...)
export const apiGet = (path: string, options?: AxiosRequestConfig): Promise<ResponseInterface> => {
  const url = path.startsWith('http') ? path : `${BaseApiUrl}${path}`;
  // TODO: sanitize data, track xhr errors, analytics... here
  return axiosApi({ url, method: 'GET', ...options }).catch(onError);
};

// apiPost - uses axios POST - path can be a relative path (uses BaseApiUrl) or absolute url path (startsWith http...)
export const apiPost = (path: string, options?: AxiosRequestConfig): Promise<ResponseInterface> => {
  const url = path.startsWith('http') ? path : `${BaseApiUrl}${path}`;
  // TODO: sanitize data, track xhr errors, analytics... here
  return axiosApi({ url, method: 'POST', ...options }).catch(onError);
};

// apiDelete - uses axios POST - path can be a relative path (uses BaseApiUrl) or absolute url path (startsWith http...)
export const apiDelete = (path: string, options?: AxiosRequestConfig): Promise<ResponseInterface> => {
  const url = path.startsWith('http') ? path : `${BaseApiUrl}${path}`;
  // TODO: sanitize data, track xhr errors, analytics... here
  return axiosApi({ url, method: 'DELETE', ...options }).catch(onError);
};

/**
 * useRequest hook for calling an API endpoint using useSWR.
 *
 * @param {string} path API endpoint path.
 * @returns {*} Returns { data, error }
 * @example
 * `const { data } = useRequest('/aidt-session/list');`
 */
export const useRequest = (path: string) => {
  if (!path) {
    throw new Error('Path is required');
  }
  const url = `${BaseApiUrl}${path}`;
  const { data, error } = useSWR(url, swrFetcher);
  return { data, error };
};

// convert curl-bash-cmd (copied from Chrome's Network Tab) format to Webbot format.
// curl 'url' \ -H '...params...' to:   curl "url" -H "...params..."
// source: https://replit.com/@ngduc/Convert-Curl-substring#index.js
export function convertCurlCmd(str: string): string {
  let output = str;
  const regex = /curl \'(.*)compressed/gs;

  const m = regex.exec(str);
  if (m && m[0]) {
    let cmd = m[0];
    // cmd = cmd.replace(/\"/g, '""'); // replace " with "" so it can work, ex: "paramString with ""doublequotes"" etc."
    // cmd = cmd.replace(/ \'/g, ' "'); // convert single-quotes to double-quotes.
    // cmd = cmd.replace(/\' /g, '" ');
    cmd = cmd.replace(/\'/g, '"');
    cmd = cmd.replace(/\r?\n|\r/g, ''); // remove linebreaks.
    cmd = cmd.replace(/ \\ /g, '');
    cmd = cmd.replace(/\-\-compressed/g, '');
    if (cmd.indexOf(' > ') < 0) {
      cmd += ' > curloutput'; // auto-append "> outputfile" to the original curl command.
    }
    output = str.replace(regex, cmd);
  }
  return output;
}
