import React from 'react';
import { Link } from 'react-router-dom';
import { Spinner } from '../../components/base';
import { apiGet, apiPost, apiDelete, getLoginData } from '../../utils/apiUtil';

export function Home() {
  const [error, setError] = React.useState<any>(null);
  const [items, setItems] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const { loginData, userId } = getLoginData();
  // console.log('loginData', loginData);

  const fetchData = async () => {
    setIsLoading(true);
    const { data, error } = await apiGet(`/users/${userId}/notes?sort=executedAt:desc`);
    setError(error);
    data && setItems(data.data);
    setIsLoading(false);
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const onClickAddNote = async () => {
    const title = prompt('New Script - Title:', '');

    if (title != null) {
      await apiPost(`/users/${userId}/notes`, {
        data: { title, note: title }
      });
      await fetchData();
    }
  };

  const onClickDelete = async (item: any) => {
    if (window.confirm('Confirm deleting this item?')) {
      await apiDelete(`/users/${userId}/notes/${item.id}`);
      await fetchData();
    }
  };

  if (error) {
    return <div className="p-5 text-red-500">Error when fetching data: {error.message}</div>;
  }
  return (
    <div>
      <div className="p-5">
        <div>Your Scripts</div>

        <div className="flex flex-wrap items-baseline mt-4">
          <div className="w-1/6 mb-3 border rounded p-4 text-sm mr-4 bg-gray-200 hover:bg-blue-200">
            <a className="" href="javascript:;" onClick={onClickAddNote}>
              ＋ New Script
            </a>
          </div>

          {isLoading && <Spinner />}

          {items ? (
            (items || []).map((item: any) => (
              <div
                key={item.title}
                className="w-1/6 mb-3 border rounded p-4 text-sm mr-4 flex justify-between hover:bg-blue-100"
                style={{ height: 120 }}
              >
                <div>
                  <Link to={`/${userId}/${item.id}`}>{item.title || item.note}</Link>
                  <div className="mt-2 text-gray-500">
                    {item.executedAt ? new Date(item.executedAt.toString()).toLocaleString() : ''}
                  </div>
                </div>
                <a href="javascript:;" onClick={() => onClickDelete(item)}>
                  ✕
                </a>
              </div>
            ))
          ) : (
            <div>Loading..</div>
          )}
        </div>
      </div>

      <div className="p-5">
        <div>Explore Scripts</div>

        <div className="flex flex-wrap items-baseline mt-4">
          <ul>
            <li>U.S. Stock Market Trends</li>
            <li>San Francisco Weather and Air</li>
            <li>Latest Tech Headlines</li>
            <li>Latest Science Headlines</li>
          </ul>
        </div>
      </div>
    </div>
  );
}
