import React from 'react';
import { HashRouter as Router, Routes, Route, Link, useNavigate } from 'react-router-dom';
import { getLoginData } from './utils/apiUtil';

import { Dropdown } from './components/base';
import { Login } from './app/Login/Login';
import { Home } from './app/Home/Home';
import ItemView from './app/ItemView/ItemView';
import DocPage from './app/DocPage/DocPage';

function App() {
  const { userEmail } = getLoginData();

  const onClickSignOut = () => {
    localStorage.removeItem('ld');
    window.location.href = '/'; // TODO: use routing
  };
  return (
    <>
      <Router>
        <header className="bg-blue-200 px-5 py-1 flex justify-between items-center">
          <strong>
            <Link to="/home">
              <div className="text-xl font-normal">Webbot</div>
            </Link>
          </strong>
          <Dropdown label={userEmail}>
            <div onClick={onClickSignOut} style={{ width: 150 }}>
              Sign out
            </div>
          </Dropdown>
        </header>

        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/home" element={<Home />} />
          <Route path="/doc" element={<DocPage />} />
          <Route path="/:urlUserId/:urlScriptId" element={<ItemView />} />
          <Route path="/item/:itemId" element={<ItemView />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
