import React from 'react';
import ReactMarkdown from 'react-markdown';

export default () => {
  const markdown = `
# Documentation
This page explains Script Commands, their usages and present some examples.

### &nbsp;
- _Script_ is where you write your webbot script by putting together multiple commands.
- After your script gets executed, the _Script Output_ will hold the outputs of every command lines.
- _Content_ (HTML) will be used to generate your page.

&nbsp;
# Commands
&nbsp;
### viewport width height

Set viewport (dimension) of page.

&nbsp;
### open "URL"

Open a website URL, the page is ready to be controlled by next commands after it.

&nbsp;
<pre>
viewport 800 1000
open "https://example.com"
screenshot file1
</pre>

&nbsp;
### screenshot filename

Take a screenshot of the current page (previously opened using the "open" command).

&nbsp;
### crop inputFile x y width height outputFile

Crop the input file. Output filename is optional, default value is "inputFilename_crop"

&nbsp;
<pre>
open "https://example.com"
screenshot file1
crop file1 50 50 300 200 croppedFile1
</pre>

&nbsp;
### clickOn selector

Click on the element on the current page using query selector.

&nbsp;
### query selector!attr

Query for an element using selector. By default, return innerHTML of that element or "attr" can be used to get an attribute value.

&nbsp;
### queryItems output.json "selector" "{ keyname: 'childSelector!attr' }"

Query for children (table rows, children elements, etc.) from a "selector" (similar to JS querySelectorAll) to get all children. For each child, return a JSON structure with custom key:value

&nbsp;
<pre>
open https://web.dev/blog/
queryItems output.json "[role='list'] article" "{ title: '.w-card-base__headline--with-image', href: '.w-card-base__link!href' }"
</pre>

&nbsp;
### scroll x y

Scroll to x, y (in pixels) on the current page.

&nbsp;
### scrollIntoView selector

Scroll to the element on the current page using query selector.

&nbsp;
### typeOn selector "text"

Type a "text" in the element on the current page using query selector.

&nbsp;
### wait number

Wait (sleep) for _number_ of milliseconds.

&nbsp;
### curl "URL" params > outputFile

Execute a curl command to fetch an URL (API, image, json, etc.) with curl parameters. This command is especially useful to simulate API calls of websites which require authentication or cookies set in request headers (use Chrome Network tab to copy a request as curl command and paste it here).

&nbsp;
# TIPS

- Use this special comment "//-//" to stop (halt) your script at that line (usually for troubleshooting).
`;
  return (
    <main className="p-5">
      <ReactMarkdown children={markdown} allowDangerousHtml></ReactMarkdown>
    </main>
  );
};
