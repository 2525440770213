import React from 'react';
import LoginBox from './LoginBox';
import { useNavigate } from 'react-router-dom';
import { apiPost } from '../../utils/apiUtil';
import './LoginBox.css';

export function Login() {
  const [errorMsg, setErrorMsg] = React.useState('');
  const navigate = useNavigate();

  const onSubmit = async (formData: any) => {
    const { username: email, password, name } = formData;
    let err = null;
    let apiData = null;

    if (name) {
      const { data, error } = await apiPost('/auth/register', { data: { email, password, name } });
      err = error;
      apiData = data;
    } else {
      const { data, error } = await apiPost('/auth/login', { data: { email, password } });
      err = error;
      apiData = data;
    }

    if (apiData?.data?.token) {
      localStorage.setItem('ld', btoa(JSON.stringify(apiData))); // save to localStorage in base64
      navigate('/home');
      // window.location.href = '/home';
    } else {
      console.log('ERROR: ', err);
      setErrorMsg(err?.message || '');
    }
  };

  return (
    <div className="flex h-full text-white bg-gradient-to-br from-gray-600 via-blue-700 to-gray-800">
      <div className="w-full p-4">
        <div className="w-full">
          <h1>Webbot is...</h1>
          <h1>Information automation, so you can enjoy every day more.</h1>
        </div>
        <div className="w-full">
          <div>Simple scripts to harvest and present data from multiple sources.</div>
        </div>
        <div>
          <img className="w-1/2 py-5" src="/img/banner-01.jpg" alt="banner" />
        </div>
      </div>
      <div className="flex w-1/3 p-4">
        <LoginBox labels={['Email', 'Password']} onSubmit={onSubmit} />

        {errorMsg && <div data-id="login-error">{errorMsg}</div>}
      </div>
    </div>
  );
}
