import React from 'react';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-java';
import 'ace-builds/src-noconflict/theme-tomorrow';

const Editor = ({
  height = 150,
  readOnly = false,
  value,
  onChange
}: {
  height?: number;
  readOnly?: boolean;
  value: string;
  onChange: (code: string) => void;
}) => {
  return (
    <AceEditor
      readOnly={readOnly}
      mode="javascript"
      theme="tomorrow"
      showGutter={false}
      className={`mt-1 ${readOnly ? 'bg-gray-100' : 'bg-gray-200'}`}
      value={value}
      wrapEnabled={true}
      width={'calc(100% - 7px)'}
      height={height}
      onChange={onChange}
      name="CUSTOM_ACE_EDITOR_ID"
      editorProps={{ $blockScrolling: true }}
    />
  );
};

export default Editor;
