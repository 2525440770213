import React from 'react';
import { useParams } from 'react-router-dom';
import { Button, Accordion, Label, Icons } from '../../components/base';
import CodeEditor from '../../components/CodeEditor';
import { apiGet, apiPost, getLoginData, getWorkerUrl, convertCurlCmd } from '../../utils/apiUtil';
import { convertToTitleSlug } from '../../utils/utils';

export type UserNote = {
  _id?: String;
  user?: String;
  title?: String;
  note?: String;
  key?: String;
  params?: String;
  postExec?: String;
  html?: String;
  output?: String;
  executedAt?: String;
  autorunMins?: Number;
};

const Component = () => {
  const { urlUserId, urlScriptId } = useParams();
  const { userId } = getLoginData();
  const [item, setItem] = React.useState<UserNote>({ note: '', postExec: '' });
  const [outputs, setOutputs] = React.useState<any>([]);
  const [execResString, setExecResString] = React.useState('');
  const [autorunMins, setAutorunMins] = React.useState(30);
  const [isSaving, setIsSaving] = React.useState(false);
  const [savingErrorMsg, setSavingErrorMsg] = React.useState('');
  const [isExecuting, setIsExecuting] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState('');
  const isReadOnly = urlUserId !== userId;

  React.useEffect(() => {
    const fetchData = async () => {
      const { data, error } = await apiGet(`/users/${urlUserId}/notes/${urlScriptId}`);
      if (data) {
        const resItem = data.data;
        setItem(resItem);
        // const scriptKey = `${resItem.user}__${resItem._id}`;
        // let sp: any = localStorage.getItem('sp') || '{}'; // script permissions
        // sp = JSON.parse(sp);
        // sp[scriptKey] = { ld: localStorage.getItem('ld') }; // keep auth string for comparison later
        // localStorage.setItem('sp', JSON.stringify(sp));
      }
    };
    fetchData();
  }, []);

  const onClickSave = async () => {
    item.note = convertCurlCmd(`${item?.note}`);
    item.autorunMins = autorunMins;
    setItem(item);

    setIsSaving(true);
    const { data, error } = await apiPost(`/users/${userId}/notes/${urlScriptId}`, { data: { ...item } });
    if (error) {
      setSavingErrorMsg(error.message);
    }
    setIsSaving(false);
  };

  const onClickExecute = async () => {
    setExecResString('');
    setErrorMsg('');
    setIsExecuting(true);
    const scriptKey = `${item.user}__${item._id}`;
    const { data, error } = await apiGet(`${getWorkerUrl()}/script/${scriptKey}?${item.params}`); // TODO: use runtime params.
    if (error) {
      setErrorMsg(error.message);
      setIsExecuting(false);
      return;
    }
    console.log('onClickExecute - data', data);
    setOutputs(data);

    let str = (data?.result ?? [])
      .map((item: any) => {
        return item?.outputString ?? '';
      })
      .join('\n');
    str += '\n--- POST EXEC ---\n' + data?.postExec ?? '';
    console.log('outputStr', str);
    setExecResString('output = ' + JSON.stringify(data));
    setIsExecuting(false);
  };
  const outputPageUrl = `${getWorkerUrl()}/s/${convertToTitleSlug(`${item?.title}`)}--${item.user}__${item._id}${
    item.params ? `?${item.params}` : ``
  }`;

  return (
    <div className="editorBox">
      <div className="editorPanel p-3 pt-0">
        <Label className="w-full">
          Title{' '}
          <input
            readOnly={isReadOnly}
            className="bg-gray-200 p-2 ml-2 w-2/3 mb-2"
            defaultValue={`${item.title ?? ''}`}
            onChange={(ev: any) => {
              item.title = ev.target.value;
              setItem({ ...item });
            }}
          />
        </Label>

        <Label iconName={'QuestionMark'} iconClick={() => window.open('/#/doc')}>
          Script
        </Label>
        {isReadOnly ? <div className="text-orange-300">Readonly</div> : ''}

        <CodeEditor
          readOnly={isReadOnly}
          height={300}
          value={`${item.note}`}
          onChange={(code: string) => {
            item.note = code;
            setItem({ ...item });
          }}
        />

        <Accordion openValue={!!(item.postExec && item.postExec.length > 0)} label={<Label>Exec Params</Label>}>
          <input
            className="bg-gray-200 p-2 mr-5 w-full mb-2 code-area"
            defaultValue={`${item.params}`}
            onChange={(ev: any) => {
              item.params = ev.target.value || '';
              setItem({ ...item });
            }}
          />
        </Accordion>

        <Accordion
          openValue={!!(item.postExec && item.postExec.length > 0)}
          label={<Label>Post-Exec (Javascript)</Label>}
        >
          <CodeEditor
            readOnly={isReadOnly}
            value={`${item.postExec}`}
            onChange={(code: string) => {
              item.postExec = code;
              setItem({ ...item });
            }}
          />
        </Accordion>

        <Label>Content (HTML)</Label>
        <CodeEditor
          readOnly={isReadOnly}
          height={300}
          value={`${item.html}`}
          onChange={(code: string) => {
            item.html = code;
            setItem({ ...item });
          }}
        />

        <div className="mt-2 mb-3">
          {!isReadOnly && (
            <Button onClick={onClickSave} isLoading={isSaving}>
              Save
            </Button>
          )}
          {savingErrorMsg && <span className="text-red-500 ml-2">{savingErrorMsg}</span>}
        </div>
      </div>

      {/* --------------------------- RIGHT PANEL --------------------------- */}
      <div className="outputPanel border-l-2 pl-3 pt-2">
        <div className="flex justify-between items-center mb-2">
          <div className="flex items-center">
            <Button onClick={onClickExecute} isLoading={isExecuting} className="">
              Execute
            </Button>
            {isReadOnly ? null : (
              <div className="ml-2">
                Autorun every{' '}
                <input
                  className="px-1 border"
                  value={autorunMins}
                  type="number"
                  style={{ width: 60 }}
                  onChange={(ev) => {
                    const val = parseInt(ev.target.value);
                    setAutorunMins(val > 10 ? val : 10);
                  }}
                />{' '}
                mins
              </div>
            )}
          </div>
          {errorMsg && <span className="text-red-500 ml-2">{errorMsg}</span>}
          <a href={outputPageUrl} target="_blank" className="underline ml-2 mr-8">
            Open result page in a new tab
          </a>
        </div>

        {!isExecuting && <iframe src={outputPageUrl} width="100%" height="85%" />}

        {execResString && (
          <textarea className="bg-gray-100 p-2 mr-5 w-full code-area" rows={7} defaultValue={execResString} />
        )}
      </div>
    </div>
  );
};

export default Component;
